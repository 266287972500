import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/userSlice';
import { useCallback, useEffect, useState } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { notifService } from 'services/notifService';
import { Link } from 'react-router-dom';



export const Element = styled(Button)`
    width: 12%;
    border-radius: 0;
    height: 72px;
    paddingLeft: 20;
    paddingRight: 20;
    fontFamily: "open sans";
    borderWidth: 0;
    color: "white";
    fontWeight: "600";
}`;
export const ToolBarCustom = styled(Toolbar)`
display: "flex";
flexDirection: "row";
paddingTop: 5;
paddingBottom: 5;
marginLeft: 10;
marginRight: 50;
}`;

const MainHeader: React.FC = React.memo(() => {
  const { userInfo }: any = useSelector((state: any) => state.userDomisante);
  const firstname = userInfo.firstname;
  const lastname = userInfo.lastname;
  const currentUserRole = useSelector((state: any) => state.userDomisante.userRole);
  // const currentUserRole = userInfo['roles']
  const [tokenIsExpired, setTokenisExpired] = useState(false);
  const initialUserRoles = useSelector((state: any) =>
    state.userDomisante.userInfo ? state.userDomisante.userInfo.roles : null
  );
  const orgaName = useSelector((state: any) => state.userDomisante.orgaName);
  const orgaUnique = useSelector((state: any) => state.userDomisante.orgaUnique);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const currentUrl = window.location.pathname.split('/')[1];
  const [currentPage, setcurrentPage] = useState(window.location.pathname.split('/')[1]);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  const [isHover, setIsHover] = useState(false);
  const [isHoverOrga, setIsHoverOrga] = useState(false);
  const [unreadNotif, setUnreadNotif] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const location = useLocation();


  function checkRoles(roles: string) {
    let numRoles = roles.split('|').length;
    return numRoles;
  }

  function userHasMultipleRoles() {
    // let 
    let mutlipleRoles = checkRoles(initialUserRoles)
    return mutlipleRoles > 1
  }

  const handleButtonClick = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  // const isExpired = (userToken: string) => {
  //   const decodedJwt = parseJwt(userToken);
  //   if (decodedJwt.exp * 1000 < Date.now()) {
  //     setTokenisExpired(true)
  //     return true
  //   }
  //   else return false;
  // }

  async function getUnreadNotifs() {
    if (currentUserRole == 'professionnel de santé' || currentUserRole == 'aidant') {
      let notifs = await notifService.countUnreadNotifications(acc_token)
      if (notifs) {
        setUnreadNotif((await notifs).notifications)

      }
    }
  }

  let pagesTitle = ['Foyers', 'Tablettes', 'Objets santé', 'Bénéficiaires',
    // 'Préventions', 
    'Utilisateurs', 'Alertes', 'Profil',
    'Alertes', 'Seuil des mesures'

  ];

  let pagesTitleBurger = [
    'Foyers',
    'Tablettes',
    'Objets santé',

    'Bénéficiaires',

    'Préventions',
    'Utilisateurs',
    'Alertes',
    'Profil',
    'Déconnexion'
  ];
  let pages = [
    'get-homes',
    'tablets',
    'devices',
    // 'preventions',
    'users',
    'patients',
    'alerts',
    'profile',
    'organization-details',
    'my-organizations'
  ];

  let role = currentUserRole

  switch (role) {
    case 'organization admin':
      pagesTitle = ['Utilisateurs', 'Bénéficiaires', 'Tablettes', 'Foyers',
        // 'Objets santé',
      ];
      pagesTitleBurger = ['Utilisateurs', 'Bénéficiaires', 'Tablettes', "Changer d'organisation", "Foyers", 'Déconnexion'];
      pages = ['users', 'patients', 'tablets', 'manager-patients', 'patient-managers', 'patient-relatives', 'get-homes', 'role-choice', 'home-details', 'devices', 'profile',];
      break;
    case 'aidant':
    case 'professionnel de santé':
      pagesTitle = ['Bénéficiaires',
        // 'Préventions', 
        'Alertes', 'Seuil des mesures'];
      pagesTitleBurger = ['Bénéficiaires',
        // 'Préventions', 
        'Profil', 'Alertes', 'Seuil des mesures', 'Déconnexion'];
      pages = [
        // 'preventions',
        'patients', 'profile', 'calendar',
        'home-details', 'my-organizations', 'manager-patients', 'measure-limits',
        'patient-managers', 'mycalendar', 'patient-relatives',
        'patient-details', 'alerts', 'measure-limits', 'role-choice'];
      break;

    case 'professional':
      pagesTitle = ['Foyers', 'Tablettes', 'Objets santé',];
      pagesTitleBurger = ['Foyers', 'Tablettes', 'Objets santé', 'Profil', 'Déconnexion'];
      pages = ['get-homes', 'tablets', 'devices', 'profile'];
      break;

    case 'admin':
    case 'super admin':
      pagesTitle = [
        'Foyers',
        'Tablettes',
        'Objets santé',

        'Bénéficiaires',

        // 'Préventions',
        'Utilisateurs',
        'Organisations',

      ];
      pagesTitleBurger = [
        'Foyers',
        'Tablettes',
        'Objets santé',

        'Bénéficiaires',

        // 'Préventions',
        'Utilisateurs',
        'Organisations',
        'Profil',
        'Déconnexion'
      ];
      pages = [
        'get-homes',
        'tablets',
        'devices',
        // 'preventions',
        'users',
        'patients',
        'profile',
        'home-details',
        'organizations',
        'organization-details',
        'manager-patients',
        'patient-managers',
        'patient-relatives'
      ];
      break;
    default:
      pagesTitle = [];
      pagesTitleBurger = [];
      pages = [];
      break;
  }

  useEffect(() => {
    if (!pages.includes(currentUrl)) {
      dispatch(logout());
      navigate('/');
    } else {
    }
  }, [pages]);

  // Memoized version of tokenIsExpired
  const tokenIsExpiredMemoized = useCallback((userToken: string) => {
    const decodedJwt = parseJwt(userToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      setTokenisExpired(true)
      return true
    }
    else return false;
  }, [acc_token]);


  const getUnreadNotifsMemoized = useCallback(async () => {
    if (currentUserRole == 'professionnel de santé' || currentUserRole == 'aidant') {
      let notifs = await notifService.countUnreadNotifications(acc_token)
      if (notifs) {
        setUnreadNotif((await notifs).notifications)
      }
    }
  }, [currentUserRole, acc_token]);
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (!tokenIsExpiredMemoized(acc_token)) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getUnreadNotifsMemoized();
    }
  }, [
    location.pathname
  ]);




  ////////////////////
  // useEffect(() => {
  //   if (!tokenIsExpired) {
  //     getUnreadNotifs();
  //   }
  // }, [pages, getUnreadNotifs, tokenIsExpired]);
  ////////////////
  const setCurrentPageUrl = (page: string) => {
    switch (page) {
      case 'get-homes':
        setcurrentPage('Foyers');
        break;
      case 'tablets':
        setcurrentPage('Tablettes');
        break;

      case 'devices':
        setcurrentPage('Objets santé');
        break;

      // case 'preventions':
      //   setcurrentPage('Préventions');
      //   break;

      case 'users':
        setcurrentPage('Utilisateurs');
        break;

      case 'patients':
        setcurrentPage('Bénéficiaires');
        break;

      case 'profile':
        setcurrentPage('Profil');
        break;

      case 'alerts':
        setcurrentPage('Alertes');
        break;
      case 'measure-limits':
        setcurrentPage('Seuil des mesures');
        break;

      case 'organizations':
        setcurrentPage('Organisations');
        break;

      default:
        break;
    }
  };

  const navigation = (page: string) => {
    handleCloseNavMenu();
    switch (page) {
      case 'Foyers':
        redirect('get-homes');
        break;
      case 'Tablettes':
        redirect('tablets');
        break;

      case 'Objets santé':
        redirect('devices');
        break;

      // case 'Préventions':
      //   redirect('preventions');
      //   break;

      case 'Utilisateurs':
        redirect('users');
        break;

      case 'Bénéficiaires':
        redirect('patients');
        break;

      case 'Profil':
        redirect('profile');
        break;

      case 'Alertes':
        redirect('alerts');
        break;

      case 'Organisations':
        redirect('organizations');
        break;
      case 'Mes organisations':
        redirect('my-organizations');
        break;
      case 'Changer de rôle':
        redirect('role-choice');
        break;
      case "Changer d'organisation":
        redirect('orga-choice');
        break;
      case "Seuil des mesures":
        redirect('measure-limits');
        break;


      case 'Déconnexion':
        dispatch(logout());
        navigate('/');
        break;
      default:
        break;
    }
  };

  setCurrentPageUrl(currentPage);



  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };


  const handleMouseEnterOrga = () => {

    setIsHoverOrga(true);
  };

  const handleMouseLeaveOrga = () => {
    setIsHoverOrga(false);
  };

  return (
    <AppBar position="static" sx={{
      backgroundColor: '#035282', width: '100%', flex: "0 0 auto", position: "sticky",
      top: 0, zIndex: 90
    }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link to="/" >
            <Box
              component="img"
              sx={{
                mr: 1,
                display: { xs: 'none', md: 'flex' },
                height: '75px'
              }}
              src={process.env.PUBLIC_URL + '/Logo-final-fond-bleu.png'}
            />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

            <Tooltip title="Menu" placement="top">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pagesTitleBurger.map((page) => (
                <MenuItem key={page} onClick={() => navigation(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            sx={{
              mr: 1,
              display: { xs: 'block', md: 'none' },
              height: '80px',
              marginRight: '35%'
            }}
            src={process.env.PUBLIC_URL + '/DomiSante_Logo_White.png'}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: "2%" }}>
            {pagesTitle.map((page) => (

              <Button
                key={page}
                onClick={() => navigation(page)}
                sx={
                  currentPage === page
                    ? {
                      color: '#18C5BF',
                      fontSize: 16,
                      fontWeight: "600",
                      display: 'block',
                      padding: '1.5%',
                      marginLeft: '0.5%',
                      borderRadius: 0,
                      textTransform: "none",
                      '&:hover': {
                        backgroundColor: '#035282',
                        color: 'white'
                      }
                    }
                    : {
                      backgroundColor: '#035282',
                      textTransform: "none",
                      fontSize: 16,
                      fontWeight: "600",
                      color: 'white',
                      display: 'block',
                      padding: '1.5%',
                      marginLeft: '0.5%',
                      borderRadius: 0,
                      '&:hover': {
                        color: '#18C5BF'
                      }
                    }
                }
              >
                {page}
                {(page === 'Alertes' && unreadNotif !== 0) && (
                  <span
                    style={{
                      marginLeft: '5px',
                      marginBottom: "1px",
                      backgroundColor: '#F4C274',
                      color: 'white',
                      borderRadius: '50%',
                      width: '26px',
                      height: '26px',
                      textAlign: 'center',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: "relative",
                      bottom: "10px"
                    }}
                  >
                    {unreadNotif}
                  </span>
                )}
              </Button>
            ))}


            {role == 'organization admin' && ((!orgaUnique) ? <button
              onMouseEnter={handleMouseEnterOrga}
              onMouseLeave={handleMouseLeaveOrga}
              style={{
                display: 'flex',
                padding: '1.45%',
                fontSize: '16px',
                position: 'absolute',
                right: 200,
                fontFamily: 'open sans',
                backgroundColor: isHoverOrga ? 'white' : '#035282',
                border: 'none',
                color: isHoverOrga ? '#035282' : 'white',
                cursor: 'pointer'
              }}
              onClick={() => {
                navigate('/orga-choice');
              }}
            >
              <div>{orgaName}</div>
            </button>
              :
              <div style={{
                display: 'flex',
                padding: '1.45%',
                fontSize: '16px',
                position: 'absolute',
                right: 200,
                fontFamily: 'open sans',
                border: 'none',
              }}>{orgaName}</div>)
            }



            <button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                display: 'flex',
                padding: '1.45%',
                fontSize: '16px',
                position: 'absolute',
                right: 0,
                fontFamily: 'open sans',
                backgroundColor: (isDropdownOpen || isHover) ? 'white' : '#035282',
                border: 'none',
                color: (isDropdownOpen || isHover) ? '#035282' : 'white',
                cursor: 'pointer'
              }}
              onClick={handleButtonClick}>
              <ExitToAppIcon style={{ marginRight: 10 }} />
              <div>
                {firstname} {lastname}
              </div>
            </button>



            {isDropdownOpen && (
              <div style={{ position: 'absolute', top: '100%', right: 0, width: "15%", backgroundColor: 'white', border: '1px solid #ccc', fontWeight: "500" }}>
                <div
                  style={{ cursor: 'pointer', color: '#035282', borderBottom: '1px solid #18c5bf', paddingTop: "10px", marginLeft: "30px", marginRight: "30px", paddingBottom: "10px" }}
                  onClick={() => navigate('/profile')}
                >
                  Mon profil
                </div>
                {role == 'organization admin' && (
                  <div
                    style={{ cursor: 'pointer', color: '#035282', borderBottom: '1px solid #18c5bf', paddingTop: "10px", marginLeft: "30px", marginRight: "30px", paddingBottom: "10px" }} onClick={() => navigate('/orga-choice')}
                  >
                    Mes organisations
                  </div>)}
                {userHasMultipleRoles() && (
                  <div
                    style={{ cursor: 'pointer', color: '#035282', borderBottom: '1px solid #18c5bf', paddingTop: "10px", marginLeft: "30px", marginRight: "30px", paddingBottom: "10px" }} onClick={() => navigate('/role-choice')}
                  >
                    Changer de rôle
                  </div>)}
                <div
                  style={{ cursor: 'pointer', color: '#035282', paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "10px" }}
                  // onClick={() => handleMenuItemClick('deconnexion')}
                  onClick={() => dispatch(logout())}
                >
                  Déconnexion
                </div>
              </div>
            )}



          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
})

export default MainHeader;
