import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { usersService } from 'services/usersService';
import '../../styles/patient_info.css'
import Constants from 'components/ReusableDivs/Constants';
import OrangeButton from 'components/InputControls.tsx/OrangeButton';
import { measuresService } from 'services/measuresService';
import { LargeCardHeading } from 'components/Cards/CardItems';
import Transmissions from 'components/ReusableDivs/Transmissions';
import { transmissionService } from 'services/transmissionService';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBox from '@mui/material/Checkbox';
import BlueSkyButton from 'components/InputControls.tsx/BlueSkyButton';
import { Typography } from '@mui/material';
import { CancelBtn, ConfirmBtn, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { authService } from 'services/authService';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import { format } from 'date-fns'
import { colors } from 'utils/Constants';
import { ActionsWrapper } from './PatientManagers';

const PatientDetails = () => {
  let homeEmptyValues = { adress1: '', adress2: '', zipCode: null, city: '' }
  const organizationsWithAllConstants = process.env.REACT_APP_ORGAS_ALL_CONSTANTS

  const [patientInfo, setPatientInfo] = useState<{
    firstname: string,
    lastname: string,
    email: string,
    birthDate: string | null,
    phoneNumber: string,
    gender: string,
    ssn: string,
    home: typeof homeEmptyValues

  }>({
    firstname: '',
    lastname: '',
    email: '',
    birthDate: '',
    phoneNumber: '',
    gender: '',
    ssn: '',
    home: homeEmptyValues
  })


  const [birthDate, setbirthDate] = useState(null)

  const { patientId } = useParams()! ?? 'undefined';
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const [bloodPressureLimits, setBloodPressureLimits] = useState()
  const [temperatureLimits, setTemperatureLimits] = useState()
  const [weightLimits, setWeightLimits] = useState()
  const [bloodSugarLimits, setbloodSugarLimits] = useState()
  const [heartRateLimits, setHeartRateLimits] = useState()
  const [spo2Limits, setSpo2Limits] = useState()
  const [stepsLimits, setStepsLimits] = useState()
  const [temperatureArray, setTemperatureArray] = useState([]);
  const [weightArray, setWeightArray] = useState([]);
  const [glycoArray, setglycoArray] = useState([])
  const [painArray, setpainArray] = useState([])
  const [doloplusArray, setdoloplusArray] = useState([])
  const [stoolArray, setstoolArray] = useState([])
  const [urineArray, seturineArray] = useState([])
  const [urineProbeArray, setUrineProbeArray] = useState([])

  const [bloodpressureArray, setBloodpressureArray] = useState([]);
  const [spo2Array, setSpo2Array] = useState([]);
  const [heartRateArray, setHeartRateArray] = useState([]);
  const [transmissionsArray, setTransmissionsArray] = useState([]);
  const [newTransmission, setNewTransmission] = useState({
    patientId: '',
    message: '',
    title: '',
    visibleForPatient: false
  });
  const [transmissionVisibleToPatient, setTransmissionVisibleToPatient] = useState(false);
  const [openTransmissionModal, setOpenTransmissionModal] = useState(false);
  const [transmissionTitle, setTransmissionTitle] = useState('');
  const [transmissionMessage, setTransmissionMessage] = useState('');
  const [transmissionLoading, setTransmissionLoading] = useState(true);
  const [measureLoading, setmeasureLoading] = useState(true);
  const [hasAllConstants, sethasAllConstants] = useState(false)
  const [refresh, setrefresh] = useState(false)

  const navigate = useNavigate();

  const location = useLocation();
  async function Init(patientId: string) {
    const currentTime = new Date().getTime();

    let patient_info
    patient_info = await usersService.fetchPatientInfo(acc_token, patientId)
    setPatientInfo({
      firstname: patient_info.firstname ? patient_info.firstname : "",
      lastname: patient_info.lastname ? patient_info.lastname : "",
      birthDate: patient_info.birthDate ? format(new Date(patient_info.birthDate), 'dd/MM/yyyy') : null,
      email: patient_info.email ? patient_info.email : "",
      phoneNumber: patient_info.phoneNumber ? patient_info.phoneNumber : "",
      ssn: patient_info.ssn ? patient_info.ssn : "",
      home: patient_info.home ? patient_info.home : homeEmptyValues,
      gender: patient_info.gender ? patient_info.gender : "",
    })
    setbirthDate(patient_info.birthDate ? patient_info.birthDate : null)
    let hasAllConstants = organizationsWithAllConstants ? organizationsWithAllConstants.includes(patient_info["organization"]["id"]) : false
    sethasAllConstants(hasAllConstants)

    let blood_pressure_response = await measuresService.getMeasures(acc_token, patientId, 'BLOOD_PRESSURE', undefined, undefined, 4);
    let oxygen_response = await measuresService.getMeasures(acc_token, patientId, 'OXYGEN_SATURATION', undefined, undefined, 4)
    let heart_rate_response = await measuresService.getMeasures(acc_token, patientId, 'BPM', undefined, undefined, 4)
    let temperature_response = await measuresService.getMeasures(acc_token, patientId, 'TEMPERATURE', undefined, undefined, 4)
    let weight_response = await measuresService.getMeasures(acc_token, patientId, 'WEIGHT', undefined, undefined, 4)

    if (await blood_pressure_response && (await blood_pressure_response).data) {
      setBloodpressureArray((await blood_pressure_response).data)
    }
    setSpo2Array((await oxygen_response).data)
    setHeartRateArray(await (heart_rate_response).data)
    setTemperatureArray(await (temperature_response).data)
    setWeightArray(await (weight_response).data)
    if (hasAllConstants) {
      /**
        * Getting glycémie
        */
      let glyco_response = await measuresService.getMeasures(acc_token, patientId, 'BLOOD_SUGAR', undefined, undefined, 4)
      let pain_response = await measuresService.getMeasures(acc_token, patientId, 'PAIN', undefined, undefined, 4)
      let doloplus_response = await measuresService.getMeasures(acc_token, patientId, 'DOLOPLUS', undefined, undefined, 4)

      let urine_response = await measuresService.getMeasures(acc_token, patientId, 'URINE', undefined, undefined, 4)
      let urineProbe_response = await measuresService.getMeasures(acc_token, patientId, 'URINE_PROBE', undefined, undefined, 4)

      let stool_response = await measuresService.getMeasures(acc_token, patientId, 'STOOL', undefined, undefined, 4)
      setglycoArray(await (glyco_response).data ? await (glyco_response).data : [])
      setpainArray(await (pain_response).data)
      setdoloplusArray(await (doloplus_response).data)
      setstoolArray(await (stool_response).data)
      seturineArray(await (urine_response).data)
      setUrineProbeArray(await (urineProbe_response).data)
    }
    setTransmissionLoading(true)
    setmeasureLoading(false)

    let transmissions = await transmissionService.getTransmissions(acc_token, patientId)
    setTransmissionsArray((await transmissions).data)
    setTransmissionLoading(false)
    let limit_data
    limit_data = await measuresService.getMeasureLimits(acc_token, patientId)
    matchLimits(await (limit_data))
  }

  useEffect(() => {
    Init(patientId!)
  }, [refresh]);

  useEffect(() => {
    if (openTransmissionModal === true) {
      setNewTransmission({
        patientId: patientId!,
        message: transmissionMessage,
        title: transmissionTitle,
        visibleForPatient: transmissionVisibleToPatient
      })
    }

  }, [transmissionVisibleToPatient, transmissionTitle, transmissionMessage, openTransmissionModal]);

  useEffect(() => {

  }, [heartRateLimits, spo2Limits, bloodPressureLimits, weightLimits, stepsLimits, refresh])

  function matchLimits(array: any[]) {

    if (array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.measureType === "HEART_RATE_MESSAGE") {
          setHeartRateLimits(element)
        }
        if (element.measureType === "OXYGEN_SATURATION") {
          setSpo2Limits(element)
        }
        if (element.measureType === "BLOOD_PRESSURE") {
          setBloodPressureLimits(element)
        }
        if (element.measureType === "TEMP_BODY") {
          setTemperatureLimits(element)
        }
        if (element.measureType === "WEIGHT") {
          setWeightLimits(element)
        }
        if (element.measureType === "BLOOD_SUGAR") {
          setbloodSugarLimits(element)
        }
        if (element.measureType === "steps") {
          setStepsLimits(element)
        }
      }
    }

  }
  const handleTransmissionSave = async () => {
    await transmissionService.createTransmission(acc_token, newTransmission)
    handleCloseTransmission()
    setTransmissionLoading(true)
    let transmissions = await transmissionService.getTransmissions(acc_token, patientId!)
    setTransmissionsArray((await transmissions).data)
    setTransmissionLoading(false)
  }
  const handleCloseTransmission = () => {
    setTransmissionTitle('')
    setTransmissionMessage('')
    setTransmissionVisibleToPatient(false)
    setOpenTransmissionModal(false)

  }

  const handleTransmissionCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setTransmissionVisibleToPatient(isChecked);
  };

  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  function calculateAge(birthDateString: string | null) {


    if (birthDateString) {

      const birthDate = new Date(birthDateString);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
    else {
      return ''
    }
  }

  const handleDownload = async (patientId: string | undefined) => {
    try {
      // Call the function

      let response = await measuresService.exportAllMeasures(acc_token, patientId)
      // patientId

      if (response) {
        const blob = new Blob(["\ufeff", response!.data]);
        const downloadUrl = URL.createObjectURL(blob!);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `measures-${patientInfo.firstname}-${patientInfo.lastname}.csv`; // Replace with desired file name
        link.click();
        URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function getSsnToUrl(ssn: string) {
    if (Number(ssn).toString().length == 15) {

      return `&patient_nir=${ssn}`
    }
  }

  function getGender(gender: string) {
    return gender == "Homme" ? "M" : "F";
  }
  const handleTE = async () => {
    try {
      const omnidocUrl = await authService.getOmniDocUrl(acc_token)
      let url = `${omnidocUrl}patient_first_name=${patientInfo.firstname}&patient_birth_name=${patientInfo.lastname}&patient_gender=${getGender(patientInfo.gender)}&patient_birth_date=${patientInfo.birthDate}&patient_phone=${patientInfo.phoneNumber}&patient_email=${patientInfo.email}${getSsnToUrl(patientInfo.ssn)}`

      window.open(url, '_blank')
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);

      scroller.scrollTo(sectionId, {
        duration: 500,
        smooth: true,
      });
    }
  }, [location]);

  return (
    <PageWithHeader>

      <PageWrapper title={`Informations de ${patientInfo.firstname} ${patientInfo.lastname}`} backBtnContainer={true} >
        <div style={{ textAlign: 'center', padding: '20px', overflow: 'hidden', marginTop: '20px', border: '1px solid #035282', borderRadius: "10px" }}>
          <div style={{ textAlign: 'left', float: 'left', width: '70%' }}>
            <table style={{ borderSpacing: 10, borderCollapse: 'separate', fontSize: '20px' }} width="100%">
              <tbody>
                <tr>
                  <td style={type}>Civilité : </td>
                  <td style={info}>{patientInfo.gender}</td>
                </tr>
                <tr>
                  <td style={type}>Nom : </td>
                  <td style={info}>{patientInfo.lastname}</td>
                </tr>
                <tr>
                  <td style={type}>Prénom : </td>
                  <td style={info}>{patientInfo.firstname}</td>
                </tr>
                {
                  patientInfo.birthDate != "" &&
                  <tr>
                    <td style={type}>Date de naissance : </td>
                    <td style={info}>{patientInfo.birthDate} ({calculateAge(birthDate)} ans) </td>
                  </tr>}
                <tr>
                  <td style={type}>Email : </td>
                  <td style={info}>{patientInfo.email} </td>
                </tr>
                <tr>
                  <td style={type}>N° téléphone :</td>
                  <td style={info}>{formatPhoneNumber(patientInfo.phoneNumber)}</td>
                </tr>
                {
                  patientInfo.home &&
                  <tr>
                    <td style={type}>Foyer :</td>
                    <td style={info}>{patientInfo.home.adress1}, {patientInfo.home.adress2}, {patientInfo.home.zipCode}, {patientInfo.home.city}</td>
                  </tr>
                }

              </tbody>
            </table>
          </div>

          <div style={{ float: 'right', width: '20%', textAlign: 'right' }}>
            {/* <OrangeButton
              title={'Agenda'}
              onClick={() =>
                navigate(`/calendar/${patientInfo.firstname}/${patientInfo.lastname}/${patientId}`)
              }
            />
            <OrangeButton
              title={'Ses proches'}
              onClick={() =>
                navigate(`/patient-relatives/${patientInfo.firstname}/${patientInfo.lastname}/${patientId}`)
              }
            /> */}
            {/* <OrangeButton
              title={'Téléexpertise'}
              onClick={() => handleTE()}
            /> */}
            <OrangeButton
              title={'Exporter les mesures'}
              onClick={() => handleDownload(patientId)}
            />
          </div>
        </div>

        {!accordionOpen ? (
          <div style={{
            backgroundColor: "rgb(238, 241, 242, 0.52)",
            borderRadius: "10px", marginTop: "80px", width: "100%", height: "125px",
            // alignItems: "center", display: "flex", flexDirection: "row"
          }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px", position: "relative", top: "35px", }}>
              <LargeCardHeading style={{ margin: 'auto' }}>
                {"Transmissions"}
              </LargeCardHeading>
              <Box sx={{
                position: 'absolute',
                right: 20,
                top: 0,
                color: colors.dark_blue,
                alignItems: "center",
              }}>
                <KeyboardArrowRightIcon
                  titleAccess={'Ajouter une transmission'}
                  onClick={() => setAccordionOpen(true)}
                  style={{ color: '#F4C274', fontSize: '60px', cursor: 'pointer', marginRight: "10px" }}
                />
              </Box>
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "rgb(238, 241, 242, 0.52 )", borderRadius: "10px", marginTop: "80px", width: "100%", height: "550px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px", position: "relative", top: "35px", }}>
              <LargeCardHeading style={{ margin: 'auto' }}>
                {"Transmissions"}
              </LargeCardHeading>

              <Box sx={{
                position: 'absolute',
                right: 20,
                top: 0,
                color: colors.dark_blue,
                alignItems: "center",
              }}>
                <KeyboardArrowDownIcon
                  onClick={() => setAccordionOpen(false)}
                  style={{ color: '#F4C274', fontSize: '60px', cursor: 'pointer', marginRight: "10px" }}
                />
              </Box>

            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", position: "relative", top: "30px", }}>
              <AddCircleOutlineIcon
                titleAccess={'Ajouter une transmission'}
                onClick={() => setOpenTransmissionModal(true)}
                style={{ color: '#F4C274', fontSize: '30px', cursor: 'pointer', marginRight: "20px" }}
              />
            </div>

            {transmissionLoading ? (
              <div style={{ marginTop: "10%" }}>
                <SpinnerComponent />
              </div>)
              : (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "auto",
                  height: "350px",
                  maxHeight: "45vh",
                  position: "relative", top: "45px"
                }}>
                  {transmissionsArray.map((transmission, index) => (
                    <Transmissions
                      key={index}
                      transmissionData={{
                        title: transmission['title'],
                        message: transmission['message'],
                        createdAt: transmission['createdAt'],
                        createdByProfessional: {
                          firstname: transmission['createdByProfessional']['firstname'],
                          lastname: transmission['createdByProfessional']['lastname'],
                        },
                      }}
                    ></Transmissions>
                  ))}
                </div>
              )}


          </div>

        )}

        <section id="constants">
          <div style={{ backgroundColor: "rgb(238, 241, 242, 0.52 )", borderRadius: "10px", marginTop: "80px" }}>
            <LargeCardHeading style={{ position: "relative", top: "40px", }} >{"Constantes"}</LargeCardHeading>
            <div className="grid-container">
              <Constants title={'Tension'}
                logo={process.env.PUBLIC_URL + '/blood-pressure.png'} type={"BLOOD_PRESSURE"}
                measurelimits={bloodPressureLimits}
                measureArray={bloodpressureArray}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                setmeasurelimits={setBloodPressureLimits}
              ></Constants>
              <Constants title={'Fréquence Cardiaque'} logo={process.env.PUBLIC_URL + '/heart-beat.png'} type={"HEART_RATE_MESSAGE"}
                measurelimits={heartRateLimits}
                setmeasurelimits={setHeartRateLimits}
                measureArray={heartRateArray}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
              ></Constants>
              <Constants title={'Température Corporelle'} logo={process.env.PUBLIC_URL + '/celsius.png'} type={"TEMP_BODY"}
                measurelimits={temperatureLimits} measureArray={temperatureArray}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                setmeasurelimits={setTemperatureLimits}

              ></Constants>
              <Constants title={'Saturation en oxygène'} logo={process.env.PUBLIC_URL + '/blood-ox.png'} type={"OXYGEN_SATURATION"}
                measurelimits={spo2Limits} measureArray={spo2Array}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                setmeasurelimits={setSpo2Limits}

              ></Constants>
              <Constants title={'Poids'} logo={process.env.PUBLIC_URL + '/weighing-scale.png'} type={"WEIGHT"}
                measurelimits={weightLimits} measureArray={weightArray}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                setmeasurelimits={setWeightLimits}

              ></Constants>

              {/* {
                !hasAllConstants &&
                <Constants title={'Nombre de Pas'} logo={process.env.PUBLIC_URL + '/Pas.png'} type={"steps"}
                  measurelimits={stepsLimits} measureArray={[]}
                  measureLoading={measureLoading}
                  setrefresh={setrefresh}
                  refresh={refresh}
                  setmeasurelimits={setStepsLimits}

                ></Constants>
              } */}
              <Constants
                title={'Taux de glycémie'}
                logo={process.env.PUBLIC_URL + '/gluco2.png'}
                type={"BLOOD_SUGAR"}
                measurelimits={bloodSugarLimits}
                measureArray={glycoArray}
                measureLoading={measureLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                setmeasurelimits={setbloodSugarLimits} />
              {/* {
                hasAllConstants && <>

                  <Constants
                    title={'Taux de glycémie'}
                    logo={process.env.PUBLIC_URL + '/gluco2.png'}
                    type={"BLOOD_SUGAR"}
                    measurelimits={bloodSugarLimits}
                    measureArray={glycoArray}
                    measureLoading={measureLoading}
                    setrefresh={setrefresh}
                    refresh={refresh}
                    setmeasurelimits={setbloodSugarLimits} />
                  <Constants
                    title={'Douleur'}
                    logo={process.env.PUBLIC_URL + '/neck.png'}
                    type={"PAIN"}
                    measurelimits={stepsLimits}
                    measureArray={painArray}
                    measureArray2={doloplusArray}
                    measureLoading={measureLoading}
                    setrefresh={setrefresh}
                    refresh={refresh}
                    setmeasurelimits={setStepsLimits} />
                  <Constants
                    title={'Élimination urinaire'}
                    logo={process.env.PUBLIC_URL + '/dark-urine.png'}
                    type={"URINE"}
                    measurelimits={stepsLimits}
                    measureArray={urineArray}
                    measureArray2={urineProbeArray}
                    measureLoading={measureLoading}
                    setrefresh={setrefresh}
                    refresh={refresh}
                    setmeasurelimits={setStepsLimits} />
                  <Constants
                    title={'Élimination fécale'}
                    logo={process.env.PUBLIC_URL + '/diarrhea.png'}
                    type={"STOOL"}
                    measurelimits={stepsLimits} measureArray={stoolArray}
                    measureLoading={measureLoading}
                    setrefresh={setrefresh}
                    refresh={refresh}
                    setmeasurelimits={setStepsLimits} />
                </>

              } */}
            </div>
          </div>
        </section>
      </PageWrapper>


      <Dialog open={openTransmissionModal}
        onClose={handleCloseTransmission}
        maxWidth="md" fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 99 }}
      >

        <StyledDialogTitle sx={{}} id="customized-dialog-title">
          Ajouter une transmission
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseTransmission()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledDialogContent>
          <div id="alert-dialog-description" style={{ fontSize: "20px", fontWeight: '100', fontFamily: 'open sans' }}>
            <div style={{ ...styles.limit, display: 'flex', alignItems: 'center' }}>
              <span>Titre</span>
              <input
                type="text"
                placeholder="Titre"
                value={transmissionTitle}
                onChange={(e) => setTransmissionTitle(e.target.value)}
                style={{ marginLeft: '10px', width: '80%', fontSize: "20px" }}
              />
            </div>

            <div style={{ ...styles.limit, display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <span >Message</span>
              <textarea
                rows={5}
                value={transmissionMessage}
                onChange={(e) => setTransmissionMessage(e.target.value)}
                style={{ width: '82%', resize: 'none', borderRadius: '5px', fontSize: "15px", fontFamily: 'open sans' }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 6, marginTop: '20px', }}>
              <CheckBox
                checked={transmissionVisibleToPatient}
                onChange={handleTransmissionCheckboxChange}
                inputProps={{ 'aria-label': 'Afficher au patient' }}
                style={{ color: transmissionVisibleToPatient ? '#18C5BF' : '#035282', borderColor: 'inherit' }}
              />
              <Typography style={{ color: '#035282' }}> Afficher au patient</Typography>
            </div>
          </div>
        </StyledDialogContent>
        <DialogActions>
          <StyledDialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={handleCloseTransmission} style={{ cursor: 'pointer' }}> Annuler</CancelBtn>
              <ConfirmBtn onClick={() => handleTransmissionSave()}>{"Confirmer"}</ConfirmBtn>
            </ActionsWrapper>
          </StyledDialogActions>
        </DialogActions>
      </Dialog>


    </PageWithHeader >
  );
};
const type = {
  width: '350px',
  color: '#035282',
  fontWeight: 'bold'
};
const info = { fontWeight: '100', backgroundColor: '#fff' }



const styles = {
  units: {
    marginBottom: '-10px',
    marginLeft: '5px',
    fontSize: '17px',
    fontWeight: '10',
    color: '#035282', fontFamily: "open sans"
  },
  values: {
    fontWeight: '100', fontSize: '15px', fontFamily: "open sans"
  },
  container: {
    marginTop: '20px',
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    width: '85%',
    height: '250px',
    borderRadius: '10px'
  },
  line: {
    color: '#035282',
    borderBottom: '2px solid #F4C274',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: "open sans"
  },
  limit: {
    display: "flex", alignItems: "center", justifyContent: "space-between"
  },
  title: {
    marginTop: "10px", color: "#035282"
  },
  buttons_layout: {
    display: 'flex', padding: '20px', gap: '20px'
  },
  datePicker_layout: {
    display: "flex", alignItems: "left", justifyContent: "left", marginLeft: "25px"
  },
  datepicker_text: {
    paddingTop: "5px", paddingBottom: "5px", fontFamily: "open sans",
  }


};
export default PatientDetails;
