import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TableBody, TableContainer, TableHead, TableRow, Tooltip, } from '@mui/material';
import { usersService } from '../../services/usersService';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import IconButton from '@mui/material/IconButton';
import { GiThreeFriends } from "react-icons/gi";
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { Link, useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { StyledTable, StyledTableCell, StyledTableRow } from 'styles/tables/TableStyles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { format } from 'date-fns'
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  Box, Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { ActionsWrapper } from './UsersList';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import OrangeButton from 'components/InputControls.tsx/OrangeButton';
import HomeIcon from '@mui/icons-material/Home';
import NewPatient from 'screens/patients/NewPatient';
import { validatePatient } from 'validators/UserValidators/newPatientValidator';
import { measuresService } from 'services/measuresService';
import { Role, colors } from 'utils/Constants';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import ModeEditIcon from '@mui/icons-material/ModeEdit';



export const StyledHead = styled.div`
display:flex;
flex-direction:row;
gap:20px;
margin-bottom:50px;
align-items:center;
justify-content:flex-end;
padding-right:8%;
`
export const DatePickerWrapper = styled.div`
padding-top:10px;
`
function Patients() {
  let initialFields = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    external_ref: "",
    id: ""
  }
  const [patients, setPatients] = useState([]);
  const [edit, setEdit] = useState(false)


  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthdate, setBirthDate] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showErrorLabel, setshowErrorLabel] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const userRole = useSelector((state: any) => state.userDomisante.userRole);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const orgaId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const [isLoading, setisLoading] = useState(false)
  const shouldApplyCursorStyle = userRole === 'professionnel de santé' || userRole === 'aidant';
  const [userDetails, setuserDetails] = useState(initialFields)
  const [helloCareAccount, sethelloCareAccount] = useState(false);
  const [anamneseAccount, setanamneseAccount] = useState(false);
  const [refresh, setrefresh] = useState(false)
  const [patientToDelete, setpatientToDelete] = useState("")
  const [patientHome, setpatientHome] = useState(null)
  const [patientToDeleteDetails, setpatientToDeleteDetails] = useState({
    firstname: "",
    lastname: ""
  })

  let emptyErrors = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    external_ref: ""
  }
  const [userErrors, setUserErrors] = useState(emptyErrors);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
    emptyPatientFields()
    setisError(false);
    setIsSuccess(false)
  }
  function emptyPatientFields() {
    setpatientToDelete("")
    setpatientToDeleteDetails({
      firstname: "",
      lastname: "",
    })
    setpatientHome(null)
  }

  async function deletePatient() {
    let deleteResponse = await usersService.deletePatient(patientToDelete, acc_token)
    if (deleteResponse) {
      setrefresh(!refresh)
      setIsSuccess(true)
      emptyPatientFields()
    } else {
      setisError(false)
    }
  }


  useEffect(() => {
    getPatientsMemoized(setPatients, acc_token, orgaId);
  }, [refresh])

  async function getPatientsMemoized(setPatients: any, acc_token: string, orgaId: string) {
    if ((userRole === 'organization admin' || userRole === 'admin' || userRole === 'aidant' || userRole === 'professionnel de santé') && orgaId != null) {
      usersService.getPatients(setPatients, acc_token, orgaId);

    } else {
      usersService.getPatients(setPatients, acc_token);
    }
    // if (patients.length > 0) {
    //   // formatPatientsData(patients)
    // }
  }


  const handleSearch = () => {
    console.log("Passing in handleSearch")
    /**
     * *Rajouter des conditions pour le lancement de la recherche
     */
    if (userRole == 'organization admin' || userRole == 'aidant' || userRole == 'professionnel de santé') {

      if (lastname !== '' || name !== '' || birthdate !== '') {
        usersService.findPatients(lastname, name, birthdate, setPatients, acc_token, orgaId);
      } else if (lastname === '' && name === '' && birthdate === '') {
        usersService.getPatients(setPatients, acc_token, orgaId);
      }
    }
    else {
      if (lastname !== '' || name !== '' || birthdate !== '') {
        usersService.findPatients(lastname, name, birthdate, setPatients, acc_token);
      } else if (lastname === '' && name === '' && birthdate === '') {
        usersService.getPatients(setPatients, acc_token);
      }
    }

  };

  const navigate = useNavigate();
  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  function closeModal() {
    setIsOpen(false);
  }

  function cancelAddingUser() {
    setuserDetails(initialFields)
    setanamneseAccount(false)
    sethelloCareAccount(false)
    setisLoading(false)
    setIsSuccess(false)
    setUserErrors(emptyErrors)
  }

  async function createPatient() {
    let data = { ...userDetails, organizationId: orgaId, }

    if (validatePatient(data, setUserErrors)) {
      setisLoading(true)

      let response = await usersService.addNewPatient({ ...data }, helloCareAccount, anamneseAccount, acc_token)

      if (response) {
        console.log("Patient created successfully ", response)
        setIsSuccess(true)
        setisError(false)
        setrefresh(!refresh)
      }
      else {
        cancelAddingUser()
        setisError(true)
      }
      setisLoading(false)
    }
  }

  async function updatePatient() {
    let data = {
      ...userDetails, organizationId: orgaId,
      createAnamneseAcc: anamneseAccount,
      createHelloCareAcc: helloCareAccount
    }

    if (validatePatient(data, setUserErrors)) {
      setisLoading(true)

      let response = await usersService.updateUserData({ ...data }, acc_token, userDetails.id);

      if (response) {
        console.log("Patient updated successfully ", response)
        setIsSuccess(true)
        setisError(false)
        setrefresh(!refresh)
      }
      else {
        cancelAddingUser()
        setisError(true)
      }
      setisLoading(false)
    }
  }


  const handleDownload = async () => {
    try {
      // Call the function

      let response = await measuresService.exportAllMeasures(acc_token, undefined, orgaId)

      if (response) {
        const blob = new Blob(["\ufeff", response!.data]);
        const downloadUrl = URL.createObjectURL(blob!);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `measures.csv`; // Replace with desired file name
        link.click();
        URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWithHeader>
      <PageWrapper title="Liste des Bénéficiaires">
        <StyledHead >
          <InputBlueBottom
            type="text"
            placeholder="Prénom"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputBlueBottom
            type="text"
            placeholder="Nom"
            name="lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <InputBlueBottom
            type="text"
            placeholder="Date de naissance"
            name="birthdate"
            value={birthdate}
            onChange={(e) => { setBirthDate(e.target.value) }}
          />
          <div style={{ minWidth: "150px", display: "flex", flexDirection: "row" }}>
            <ActionButton title="Chercher" onClick={handleSearch} />
          </div>
          {
            (userRole.includes(Role.ORGANIZATION_ADMIN) || userRole.includes(Role.ADMIN) || userRole.includes(Role.SUPER_ADMIN)) &&
            <Tooltip title="Ajouter un bénéficiaire" placement="top">
              <div style={{ minWidth: "150px", display: "flex", flexDirection: "row", marginLeft: "30px" }}>
                <OrangeButton title="Ajouter" onClick={() => setIsOpen(true)} />
              </div>
            </Tooltip>
          }

          <Tooltip title="Exporter toutes les mesures" placement="top">
            <div style={{ minWidth: "150px", display: "flex", flexDirection: "row", marginLeft: "0px" }}>
              <OrangeButton title="Exporter" onClick={() => handleDownload()} />
            </div>
          </Tooltip>
        </StyledHead>


        <TableContainer style={{ display: "flex", justifyContent: "center", }} >
          <StyledTable size="medium" aria-label="a dense table" >
            <TableHead>
              <TableRow >
                <StyledTableCell align="left">
                  Nom
                </StyledTableCell>
                {/* <StyledTableCell align="left">
                  Prénom
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  Email
                </StyledTableCell>
                <StyledTableCell align="center">
                  Date de naissance{' '}
                </StyledTableCell>
                <StyledTableCell align="center">
                  Numéro de téléphone{' '}
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  Numéro de sécurité sociale{' '}
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  Foyer{' '}
                </StyledTableCell>
                {/* {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                  <StyledTableCell align="center">
                    Agenda{' '}
                  </StyledTableCell>
                )} */}
                <StyledTableCell align="center">
                  Professionnels{' '}
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  Proches{' '}
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={shouldApplyCursorStyle ? { cursor: 'pointer' } : {}}>
              {patients.map((row) => {
                return (
                  <StyledTableRow key={row["id"]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      onClick={() => {
                        if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                          redirect(`patient-details/${row['id']}`);
                        }
                      }}
                    >
                      {row['lastname']}  {row['firstname']}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                      onClick={() => {
                        if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                          redirect(`patient-details/${row['id']}`);
                        }
                      }}
                    >
                      {row['email']}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                          redirect(`patient-details/${row['id']}`);
                        }
                      }}
                    >
                      {row['birthDate'] ? format(new Date(row['birthDate']), 'dd/MM/yyyy') : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                          redirect(`patient-details/${row['id']}`);
                        }
                      }}
                    >
                      {formatPhoneNumber(row['phoneNumber'])}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row['home'] != null ? (
                        <Tooltip title="Afficher les détails du foyer" placement="top">
                          <IconButton
                            to={`/home-details/${[row['home']['id']]}`}
                            component={Link}>
                            <HomeIcon style={{ color: "#035282", fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </StyledTableCell>


                    {/* {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                      <StyledTableCell align="center">
                        <Tooltip title="Afficher l'agenda" placement="top">
                          <IconButton
                            component={Link}
                            to={`/calendar/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                          >
                            <CalendarMonthIcon style={{ color: '#18C5BF' }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    )} */}

                    <StyledTableCell align="center">
                      <Tooltip title="Afficher les aidants" placement="top">
                        <IconButton
                          component={Link}
                          to={`/patient-managers/${row['firstname']}/${row['lastname']}/${row['id']}`}>
                          <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      <Tooltip title="Afficher les proches" placement="top">
                        <IconButton
                          component={Link}
                          to={`/patient-relatives/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                        >
                          <GiThreeFriends style={{ color: '#F4C274', margin: 'auto', }} size={25} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      <p style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Modifier la tablette" placement="top">
                          <IconButton onClick={() => {
                            setEdit(true)
                            setIsOpen(true)
                            setuserDetails(row)
                            console.log("Patient ", row)

                            console.log("Patient anamneseActive", row["anamneseActive"], row["anamneseActive"] == true)

                            console.log("Patient hellocareActive", row["hellocareActive"] == true, row["hellocareActive"] == true ? true : false)

                            setanamneseAccount(row["anamneseActive"])
                            sethelloCareAccount(row["hellocareActive"])

                          }} >
                            <ModeEditIcon style={{ color: "#18C5BF" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer le bénéficiaire" placement="top">
                          <IconButton
                            onClick={() => {
                              setpatientToDelete(row["id"]); setDeleteModalIsOpen(true); row['home'] != null && setpatientHome(row["home"]);

                              setpatientToDeleteDetails({
                                firstname: row["firstname"],
                                lastname: row["lastname"],
                              });
                            }}
                          >
                            <DeleteIcon style={{ color: colors.dark_blue, margin: 'auto', }} />
                          </IconButton>
                        </Tooltip>
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>

          </StyledTable>
        </TableContainer>
      </PageWrapper>

      <Dialog open={modalIsOpen} fullWidth={true} style={{ zIndex: 99 }}
        onClose={(event: any, reason: string) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setIsOpen(false);
          }

        }}
        maxWidth='md'>
        <StyledDialogTitle>
          {edit ? "Modifier les données d'un bénéficiaire" : "Ajouter un bénéficiaire"}
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { closeModal(); cancelAddingUser(); setisError(false) }}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent>
          {!isLoading && !isError && !isSuccess && <>
            <StyledDialogContentText>
              Veuillez renseigner les informations du bénéficiaire
            </StyledDialogContentText>
            {
              showErrorLabel == true && <Box ><Typography style={{ color: "red", }}>Vous devez saisir les donéees d'un bénéficiaire au moins </Typography></Box>
            }
            <NewPatient userDetails={userDetails} setuserDetails={setuserDetails}
              sethelloCareAccount={sethelloCareAccount} setanamneseAccount={setanamneseAccount}
              anamneseAccount={anamneseAccount} helloCareAccount={helloCareAccount}
              userErrors={userErrors}
            />
          </>
          }
          {
            isLoading &&
            <>
              <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
              </Box>
            </>
          }
          {
            isSuccess && !isError && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>{edit ? "Bénéficiaire modifié avec succès." : "Bénéficiaire ajouté avec succès."}</StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            isError && !isSuccess &&
            <>
              <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer ...</StyledDialogMessage>
                </Box>
              </Box>
            </>
          }
        </StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            {
              !isLoading && !isError && !isSuccess ?
                <CancelBtn onClick={() => { closeModal(); cancelAddingUser(); setisError(false) }}>Annuler</CancelBtn> :
                <CancelBtn onClick={() => { closeModal(); cancelAddingUser(); setisError(false) }}>Fermer</CancelBtn>
            }
            {
              !isLoading && !isError && !isSuccess &&
              <ConfirmBtn onClick={() => { edit ? updatePatient() : createPatient() }}>
                {edit ? "Modifier" : "Ajouter"}
              </ConfirmBtn>
            }
          </ActionsWrapper>
        </DialogActions>
      </Dialog>
      <Dialog
        style={{ zIndex: 99 }}
        open={deleteModalIsOpen}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle >
          {"Êtes-vous sûr de bien vouloir supprimer ce patient ?"}
        </StyledDialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => closeDeleteModal()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent>
          {!isError && !isSuccess && <StyledDialogContentText id="alert-dialog-description">

            <Typography style={{ fontWeight: "600", display: "inline" }}>{`${patientToDeleteDetails.firstname} ${patientToDeleteDetails.lastname} `}</Typography>

          </StyledDialogContentText>
          }

          {
            isError && <Box sx={{ padding: "50px 0px" }}>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>{`Une erreur est survenue, veuillez réessayer plus tard ! `}</StyledDialogMessage>
              </Box>
            </Box>
          }
          {!isError && !isSuccess &&
            <StyledDialogContentText >
              Voulez-vous supprimer quand même ?
            </StyledDialogContentText>
          }

          {
            isSuccess &&
            <Box sx={{ padding: "50px 0px" }}>

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Élément supprimé avec succès.</StyledDialogMessage>
              </Box>
            </Box>
          }
        </ StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            <CancelBtn onClick={() => closeDeleteModal()}>{
              !isLoading && !isError && !isSuccess ?
                "Annuler" : "Fermer"
            }</CancelBtn>
            {
              !isError && !isSuccess && <ConfirmBtn onClick={() => deletePatient()} autoFocus>
                Supprimer
              </ConfirmBtn>
            }

          </ActionsWrapper>

        </DialogActions>
      </Dialog>
    </PageWithHeader >
  );
}

export default Patients

